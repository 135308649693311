<template>
  <main class="page page-activity">
    <BreadcrumbsComponent title="Деятельность" />
    <div class="page__container">
      <div class="page__content">
        <ul class="page-activity__list">
          <li v-for="(activity, i) in activities" :key="i">
            <ActivityItem :data="activity" white />
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/navigation/BreadcrumbsComponent.vue";
import ActivityItem from "components/ActivityItem.vue";
import DEPARTMENT from "gql/queries/department.graphql";

export default {
  name: "ActivityListPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: DEPARTMENT,
      })
      .then(({ data }) => {
        store.dispatch("activity/save", data);
      })
      .catch(() => {});
  },
  computed: {
    activities() {
      return this.$store.state.activities
        .filter((a) => a.type.code === 1) // 1 - Страница «Деятельность»
        .map((a) => ({
          title: a.title,
          icon: a.icon,
          to: { name: "activity-item", params: { id: a.id } },
        }));
    },
  },
  metaInfo: {
    title: "Деятельность",
  },
  components: { ActivityItem, BreadcrumbsComponent },
};
</script>

<style lang="stylus">
.page-activity {
  margin-bottom 50px
  +below(1280px) {
    margin-bottom 30px
  }

  &__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 30px
    +below(1280px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1024px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(420px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }
}
</style>
