<template>
  <router-link :to="data.to" class="activity-item" :class="{ 'activity-item--white': white }">
    <figure class="activity-item__icon icon" v-if="data.icon" v-html="data.icon"></figure>
    <IconComponent category="vuesax-linear" v-else name="cpu" class="activity-item__icon" />
    <h3 class="activity-item__title">{{ data.title }}</h3>
    <IconComponent category="vuesax-bold" name="arrow-right-2" class="activity-item__arrow" />
  </router-link>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "ActivityItem",
  props: {
    data: Object,
    white: Boolean,
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.activity-item {
  background: var(--gray);
  border: 1px solid var(--gray-dark);
  border-radius: 5px;
  display grid
  grid-template-columns 40px 1fr 20px
  padding 15px 30px
  padding-right 20px
  grid-gap 10px
  min-height 115px
  justify-content center
  align-items center
  transition var(--transition)
  height 100%
  +below(768px) {
    padding 15px
    min-height auto
  }

  &--white {
    background var(--white)
  }

  &:hover {
    background var(--main)
    box-shadow: 0 20px 30px var(--main-o3);
    transform scale(1.03)

    & ^[0]__title {
      color var(--white)
    }

    & ^[0]__arrow svg path {
      fill var(--white)
    }

    & ^[0]__icon svg path {
      stroke var(--white)
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.125em;
    margin 0
    line-height: 28px;
    color: var(--dark);
    transition var(--transition)
    +below(640px) {
      font-size: 1em;
      line-height: 26px;
    }
  }

  &__arrow {
    width 20px
    height: 20px;
    margin-left 10px

    svg path {
      transition var(--transition)
      fill var(--dark)
    }
  }

  &__icon {
    width 40px
    height: 40px;

    svg path {
      stroke var(--main)
    }
  }
}
</style>
